import {
  CAMPAIGN_LIST,
  CONTACT_DETAILS,
  RECIPIENT_LIST,
  EMAIL_CAMPAIGN,
  CAMPAIGN_MASS_DELETE,
  ARCHIVE_CAMPAIGNS,
  MOVE_ARCHIVE_CAMPAIGNS,
  CANCEL_ARCHIVE,
  CAMPAIGN_RECIPIENTS,
  CAMPAIGN_DUPLICATION,
  CANCEL_CAMPAIGNS,
  DUPLICATE_NAME,
  CAMPAIGN_MASS_RENAME,
  SEND_TEST_EMAIL,
  START_CAMPAIGN,
  EMAIL_PREFERENCES,
  // EMAIL_SUBSCRIPTIONS,
  GET_EMAIL_PREFERENCE_INFO,
  RE_SUBSCRIBE_TO_SENDER,
  UN_SUBSCRIBE_FROM_SENDER
} from "../constants/routes";
import { IS_ARCHIVE, IS_DRAFT } from "../utils/constants";
import { FetchApi } from "./fetchApi";

let url;
let header;
let archive_header;

export const campaignApi = {
  getEmailCampaign: (page, pageSize, is_archived = false) => {
    url = `${CAMPAIGN_LIST}?page=${page}&per_page=${pageSize}`;
    if (is_archived) {
      url = `${url}&search[is_archived]=true`;
    }
    return FetchApi({ path: url, type: "GET" });
  },

  getRecipients: (value) => {
    let url = RECIPIENT_LIST;
    if (value) {
      url += `?search[contact_detail]=${value}`;
    }
    return FetchApi({
      path: url,
      type: "GET",
    });
  },

  getStatusFilter: (page, pageSize, status, isTrue, isArchive = false) => {
    let statusParams = `&search[status]=${status}`
    let searchParam = `&search[is_archived]=${isTrue}`
    let expectDraft = `&search[except_draft_archived]=true`
    url = `${CAMPAIGN_LIST}?page=${page}&per_page=${pageSize}`
    if (status) {
      url += statusParams
      if (isArchive) {
        url += searchParam;
        archive_header = statusParams
        header = ""
      } else {
        url += expectDraft
        header = statusParams
        archive_header = ""
      }

    } else if (!status && isTrue) {
      header = "";
      archive_header = "";
      url += searchParam
    } else {
      header = ""
      archive_header = ""
      url += expectDraft
    }
    return FetchApi({
      path: url,
      type: "GET"
    });
  },

  campaginSingleDelete: (id) => {        // single delete
    return FetchApi({
      path: `${CAMPAIGN_LIST}/${id}`,
      type: "DELETE"
    });
  },

  campaginMassDelete: (data) => {        // multiple delete
    return FetchApi({
      path: `${CAMPAIGN_MASS_DELETE}`,
      type: "DELETE",
      data: JSON.stringify(data),
    });
  },

  getOverviewData: (id) => {
    return FetchApi({
      path: `${CAMPAIGN_LIST}/${id}`,
      type: "GET"
    });
  },

  createCampaign: (data) => {
    return FetchApi({
      path: `${EMAIL_CAMPAIGN}`,
      type: "POST",
      data: data,
      contentType: false,
    });
  },

  updateCampaign: (data, id) => {
    return FetchApi({
      path: `${EMAIL_CAMPAIGN}/${id}`,
      type: 'PUT',
      data: data,
      contentType: false
    })
  },

  archiveCampaign: (data, name) => {
    if (name === ARCHIVE_CAMPAIGNS) {
      url = `${ARCHIVE_CAMPAIGNS}`
    } else if (name === CANCEL_ARCHIVE) {
      url = `${CANCEL_ARCHIVE}`
    } else {
      url = `${MOVE_ARCHIVE_CAMPAIGNS}`
    }

    return FetchApi({
      path: `${EMAIL_CAMPAIGN}${url}`,
      type: 'PUT',
      data: JSON.stringify(data)
    })
  },
  campaignSearch: (page, pageSize, isTrue = '', data, isFirst) => {
    let searchArchived = `&search[is_archived]=true`
    let searchName = `&search[name]=${data}`
    let searchStatus = `&search[status]=draft`
    url = `${EMAIL_CAMPAIGN}?page=${page}&per_page=${pageSize}`
    if (isTrue === IS_ARCHIVE) {
      if (data) {
        url += `${searchName}${searchArchived}`
      } else {
        header = ''
        url += searchArchived
      }
      if (archive_header) {
        header = ''
        url += archive_header
      }
    } else if (isTrue === IS_DRAFT) {
      header = ''
      archive_header = ''
      if (data) {
        url += `${searchName}${searchStatus}`
      } else {
        url += `${searchStatus}`
      }
    } else {
      url += `&search[except_draft_archived]=true`
      if (data && !isTrue) {
        url += searchName
      }
      archive_header = ''
      if (header && !isFirst) {
        url += header
      } else {
        header = ''
      }
    }

    return FetchApi({
      path: url,
      type: 'GET'
    })
  },
  renameCampaign: (id, data) => {
    return FetchApi({
      path: `${EMAIL_CAMPAIGN}/${id}`,
      type: 'PUT',
      data: JSON.stringify(data)
    })
  },
  getReceipentsListData: function (id, page, pageSize, status) {
    let pageHeader = `page=${page}&per_page=${pageSize}`
    let campaign_id = `email_campaign_id=${id}`
    if (status) {
      url = `${CAMPAIGN_RECIPIENTS}?${campaign_id}&search[status]=${status}&`
    } else {
      url = `${CAMPAIGN_RECIPIENTS}?${campaign_id}&`
    }
    return FetchApi({ path: `${url}${pageHeader}`, type: 'GET' })
  },
  cancelCampaign: id => {
    return FetchApi({
      path: `${CANCEL_CAMPAIGNS}?email_campaign_id=${id}`,
      type: 'PUT'
    })
  },
  massRename: data => {
    return FetchApi({
      path: `${CAMPAIGN_MASS_RENAME}`,
      type: 'PUT',
      data: JSON.stringify({ data: data })
    })
  },
  sendTestEmail: data => {
    return FetchApi({
      path: `${EMAIL_CAMPAIGN}${SEND_TEST_EMAIL}`,
      type: 'POST',
      data: JSON.stringify({ data: data })
    })
  },
  getDuplicateData: function (data) {
    return FetchApi({
      path: CAMPAIGN_DUPLICATION,
      type: 'POST',
      data: JSON.stringify({ data: data })
    })
  },
  getDetail: function (id) {
    return FetchApi({
      path: `${EMAIL_CAMPAIGN}/${id}`,
      type: 'GET'
    })
  },
  getDuplicateName: function (id) {
    return FetchApi({
      path: `${DUPLICATE_NAME}?email_campaign_id=${id}`,
      type: 'GET'
    })
  },
  startCampaign: function () {
    return FetchApi({
      path: `${START_CAMPAIGN}`,
      type: 'PUT'
    })
  },
  getEmailPreference: function (token, sender_email, sender_name) {
    const encodedSenderEmail = encodeURIComponent(sender_email);
    const encodedSenderName = encodeURIComponent(sender_name);
    let URL = `${EMAIL_PREFERENCES}${GET_EMAIL_PREFERENCE_INFO}?preference_token=${token}&sender_name=${encodedSenderName}&sender_email=${encodedSenderEmail}`
    return FetchApi({
      path: URL,
      type: 'GET'
    })
  },
  un_subscribe: function (data) {
    let URL = `${EMAIL_PREFERENCES}${UN_SUBSCRIBE_FROM_SENDER}`;
    return FetchApi({
      path: URL,
      type: "PUT",
      data: JSON.stringify(data)
    })
  },
  re_subscribe: function (data) {
    let URL = `${EMAIL_PREFERENCES}${RE_SUBSCRIBE_TO_SENDER}`
    return FetchApi({
      path: URL,
      type: 'DELETE',
      data: JSON.stringify(data)
    })
  }
}
