import { ADMIN_LOGIN, DUPLICATE_MASTER_ADMIN_TEMPLATE, MASTER_ADMIN_TEMPLATES} from "../constants/routes";
import { FetchApi } from "./fetchApi";

export const MasterAdminApi = {
  
  create: (data) => {
    return FetchApi({
      path: ADMIN_LOGIN, 
      type: "POST", 
      data: data, 
      isMasterAdmin: true,
    });
  },
  getById: function (id) {
    return FetchApi({ path: `${MASTER_ADMIN_TEMPLATES}/${id}`, type: "GET", isMasterAdmin: true, });
  },
  createTemplate: function (data) {
    return FetchApi({
      path: MASTER_ADMIN_TEMPLATES,
      type: "POST",
      data: data,
      contentType: false,
      isMasterAdmin: true,
    });
  },
  updateTemplate: function (id, data) {
    return FetchApi({
      path: `${MASTER_ADMIN_TEMPLATES}/${id}`,
      type: "PUT",
      data: data,
      contentType: false,
      isMasterAdmin: true,
    });
  },
  getTemplates: (page, pageSize) => {
    return FetchApi({
      path: `${MASTER_ADMIN_TEMPLATES}?page=${page}&per_page=${pageSize}`,
      type: "GET",
      isMasterAdmin: true,
    });
  },
  deleteTemplate: (id) => {
    return FetchApi({
      path: `${MASTER_ADMIN_TEMPLATES}/${id}`,
      type: "DELETE",
      isMasterAdmin: true,
    });
  },
  duplicateTemplate: (data) => {
    return FetchApi({
      path: `${MASTER_ADMIN_TEMPLATES}${DUPLICATE_MASTER_ADMIN_TEMPLATE}`,
      type: "POST",
      data: JSON.stringify({ data: data }),
      isMasterAdmin: true,
    });
  },
};