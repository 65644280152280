// LAZY RELATED IMPORTS
import { lazy } from 'react'
import { lazyRetry } from '../utils/chunkHandle'
import LazyLoader from '../components/routesContainer'

// LOGIN & SIGNUP IMPORTS
import Login from '../pages/Login/Login'
import Signup from '../pages/Signup/Signup'
import AdminLogin from '../pages/MasterAdmin/AdminLogin'

// AUTHENTICATION IMPORTS
import { Authenticate, MasterAuthenticate } from '../AuthenticateRoute';

// NOTES, TASKS, MEETINGS, CALLS & OVERVIEW IMPORTS
import Notes from '../pages/Notes'
import Tasks from '../pages/Tasks'
import Meetings from '../pages/Meetings'
import Calls from '../pages/Calls'
import LeadDetailsComponent from '../pages/LeadDetails'

// OTHER IMPORTS
import LandingPage from '../pages/Main/LandingPage'
import Recipients from '../pages/EmailCampaigns/Recipients'
import DataPermissionPage from '../pages/RolesPermissions/DataPermissionPage'

const Campaigns = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/EmailCampaigns/Lists'), 'campaigns'))
)

const Templates = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/EmailCampaigns/TemplateList'), 'templates'))
)

const Drafts = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/EmailCampaigns/Drafts'), 'drafts'))
)

const Archive = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/EmailCampaigns/Archive'), 'archive'))
)

const WhiteListDomin = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/AccountDetail/WhiteListDomin'), 'whiteListDomin'))
)

const CompanyAddresses = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/AccountDetail/CompanyAddresses'), 'companyAddresses'))
)

const CurrentPlans = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/Subscription/CurrentPlans'), 'currentPlans'))
)

const TransactionHistorys = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/Subscription/TransactionHistorys'), 'transactionHistorys'))
)

const PaymentMethods = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/Subscription/PaymentMethods'), 'paymentMethods'))
)

const Currency = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/AccountDetail/Currency'), 'currency'))
)

const ManagePreference = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Email Campaigns/ManagePreference'), 'managePreference'))
)

const LeadOverview = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Leads/Overview'), 'leadOverview'))
)

const PipelineOverview = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Pipeline/Details/PipelineOverview/PipelineOverview'), 'pipelineOverview'))
)

const PotentialOverview = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Potential/Details/PotentialOverview/PotentialOverview'), 'potentialOverview'))
)

const DealOverview = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Deals/Details/DealsOverview/DealsOverview'), 'dealOverview'))
)

const LostOverview = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/LostLeads/Overview/Overview'), 'lostOverview'))
)

const ManageUsers = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/RolesPermissions/ManageUsers'), 'roles-users'))
)

const UserDetails = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/RolesPermissions/UserDetails'), 'roles-details'))
)

const SocialLoading = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/SocialLoading/SocialLoading'), 'social-load'))
)

const ThirdParty = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/ThirdParty/index'), 'thirdParty'))
)

const VerifyEmail = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/VerifyEmail/VerifyEmail'), 'verifyEmail'))
)

const ForgotPassword = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/AccountActivation/ForgotPassword'), 'forgotPswrd'))
)

const UpdatePassword = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/AccountActivation/UpdatePassword'), 'updatePswrd'))
)

const ChangePassword = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/AccountActivation/ChangePassword'), 'changePassword'))
)

const PasswordResetSuccess = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/AccountActivation/PasswordResetSuccess'), 'pswrdResetSuccess'))
)

const PageNotFound = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/NotFound/PageNotFound'), 'pageNotFound'))
)

const CompanyIdPopup = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Leads/CompanyIdPopup/CompanyIdPopup'), 'companyId'))
)

const ServerStatus = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/NotFound/ServerStatus'), 'serverStatus'))
)

const AboutUs = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/AboutUs'), 'aboutUs'))
)

const ContactUs = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/ContactUs'), 'contactUs'))
)

const PrivacyPolicy = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/PrivacyPolicy'), 'privacy-policy'))
)

const RefundCancellationPolicy = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/RefundCancellationPolicy'), 'refund-cancellation-policy'))
)

const TermsConditions = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/TermsConditions'), 'terms-conditions'))
)
const UsagePolicy = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/UsagePolicy'), 'policy'))
)

const UserOverviewChild = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/UserOverviewChild'), 'userChild'))
)

const CampaignOverview = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/EmailCampaigns/OverviewData'), 'campaign-overview'))
)

const DesignTemplate = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Email Campaigns/EmailTemplate/Design'), 'second-editor'))
)

const WhyPivott = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/WhyPivott'), 'whyPivott'))
)

const Blog = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/Blog'), 'blog'))
)

const Event = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/Event'), 'event'))
)

const BlogDetails = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Main/BlogDetails'), 'blog-details'))
)

const EasyEditor = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Email Campaigns/EmailTemplate/Editor'), 'editor'))
)

const Preview = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Email Campaigns/EmailTemplate/TemplatePreview'), 'preview'))
)

const StartCampaign = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Email Campaigns/StartCampaign'), 'startCampaign'))
)

const CreateCampaign = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Email Campaigns/CreateCampaign'), 'createCampaign'))
)

const LeadsList = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Leads/List'), 'leadlist'))
)

const LeadsCreate = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Leads/Create'), 'createLead'))
)

const LeadsUpdate = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Leads/Create'), 'updateLead'))
)

const Dashboard = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Dashboard'), 'dashboard'))
)

const CreatePipeline = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Pipeline/Create'), 'createpipeline'))
)

const UpdatePipeline = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Pipeline/Create'), 'updatepipeline'))
)

const Pipeline = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Pipeline/List'), 'pipelineList'))
)

const LeadDetailEditPipeline = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/common/leadDetails'), 'lead-details-pipe'))
)

const LeadDetailEditPotential = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/common/leadDetails'), 'lead-details-pote'))
)

const LeadDetailEditDeal = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/common/leadDetails'), 'lead-details-deal'))
)

const CreatePotential = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Potential/Create'), 'createpotential'))
)

const UpdatePotential = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Potential/Create'), 'updatepotential'))
)

const Potential = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Potential/List'), 'potentialList'))
)
const MyAccountDetails = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/AccountDetail/MyAccountDetails'), 'profile'))
)

const CreateDeals = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Deals/Create'), 'createdeal'))
)
const UpdateDeals = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Deals/Create'), 'updatedeal'))
)
const Deals = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Deals/List'), 'deallist'))
)
const LostLists = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/LostLeads/LostLeadsList'), 'lostlist'))
)

const ContactList = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/ContactLists/ContactList'), 'contactlist'))
)

const EditDetails = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/AccountDetails/EditDetails'), 'profileEdit'))
)

const Report = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/Reports/List'), 'reportlist'))
)

const CreateReports = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Reports/CreateReports'), 'createReport'))
)

const UpdateReports = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Reports/CreateReports'), 'updateReport'))
)

const ListReports = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Reports/ListReports'), 'reportInlist'))
)

const GlobalSearch = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/GlobalSearch/GlobalSearch'), 'globalSearch'))
)

const Password = LazyLoader(
    lazy(() => lazyRetry(() => import('../components/Personal/Password'), 'passwordSet'))
)

const MasterTemplate = LazyLoader(
    lazy(() => lazyRetry(() => import('../pages/MasterAdmin/Component/MasterTemplate'), 'masterTemplate'))
)

export const routes = [

    // Initial or Root Routes definition
    {
        path: '/',
        routeType: 'isPublicRoute',
        hasIndex: true,
        childRoutes: [
            { index: true, element: <LandingPage />, exact: true },
            { path: 'about-us', element: <AboutUs />, exact: true },
            { path: 'contact-us', element: <ContactUs />, exact: true },
            { path: 'blogs', element: <Blog />, exact: true },
            { path: 'blogs/:title', element: <BlogDetails />, exact: true },
            { path: 'event', element: <Event />, exact: true },
            { path: 'privacy-policy', element: <PrivacyPolicy />, exact: true },
            { path: 'privacyPolicy', element: <PrivacyPolicy />, exact: true },
            { path: 'refund-cancellation-policy', element: <RefundCancellationPolicy />, exact: true },
            { path: 'tnc', element: <TermsConditions />, exact: true },
            { path: 'usage-policy', element: <UsagePolicy />, exact: true },
            { path: 'why-pivott', element: <WhyPivott />, exact: true },
            // authentication related routes
            { path: 'login', element: <Authenticate><Login /></Authenticate> },
            { path: 'signup', element: <Signup /> },
            { path: 'master-admin/login', element: <MasterAuthenticate><AdminLogin /></MasterAuthenticate> },
            { path: 'auth-callback', element: <SocialLoading /> },
            { path: 'verify-email', element: <VerifyEmail />, exact: true },
            { path: 'forgot-password', element: <ForgotPassword />, exact: true },
            { path: 'update-password', element: <UpdatePassword />, exact: true },
            { path: 'change-password', element: <ChangePassword />, exact: true },
            { path: 'password-reset-success', element: <PasswordResetSuccess />, exact: true },
            { path: 'update-company', element: <CompanyIdPopup />, exact: true },
        ],
    },

    // Dashboard routes =====
    {
        title: 'Dashboard',
        parentLayout: 'AppLayout',
        childRoutes: [
            { path: '/dashboard', element: <Dashboard /> }
        ]
    },

    // Lead routes =====
    {
        path: '/lead',
        title: 'Leads',
        hasIndex: true,
        parentLayout: 'AppLayout',
        childRoutes: [
            { index: true, element: <LeadsList /> },
            { path: 'create/:createId?', element: <LeadsCreate /> },
            { path: 'update/:id', element: <LeadsUpdate /> },
            { path: ':id/note', element: <Notes type='Lead' /> },
            { path: ':id/overview', element: <LeadOverview /> },
            { path: ':id/task', element: <Tasks type='Lead' /> },
            { path: ':id/meeting', element: <Meetings type='Lead' /> },
            { path: ':id/call-information', element: <Calls type='Lead' /> },
        ],
    },
    // Pipeline routes =====
    {
        path: '/pipeline',
        title: 'Pipelines',
        hasIndex: true,
        parentLayout: 'AppLayout',
        childRoutes: [
            { index: true, element: <Pipeline /> },
            { path: 'lead-detail/update/:id', element: <LeadDetailEditPipeline /> },
            { path: 'create/:createId?', element: <CreatePipeline /> },
            { path: 'update/:id', element: <UpdatePipeline /> },
            { path: ':id/note', element: <Notes type='Pipeline' /> },
            { path: ':id/overview', element: <PipelineOverview /> },
            { path: ':id/task', element: <Tasks type='Pipeline' /> },
            { path: ':id/meeting', element: <Meetings type='Pipeline' /> },
            { path: ':id/call-information', element: <Calls type='Pipeline' /> },
            { path: ':id/lead-detail', element: <LeadDetailsComponent type='Pipeline' /> },
        ],
    },

    // Potential routes =====
    {
        path: '/potential',
        title: 'Potentials',
        hasIndex: true,
        parentLayout: 'AppLayout',
        childRoutes: [
            { index: true, element: <Potential /> },
            { path: ':id/note', element: <Notes type='Potential' /> },
            { path: ':id/overview', element: <PotentialOverview /> },
            { path: ':id/task', element: <Tasks type='Potential' /> },
            { path: ':id/meeting', element: <Meetings type='Potential' /> },
            { path: ':id/call-information', element: <Calls type='Potential' /> },
            { path: ':id/lead-detail', element: <LeadDetailsComponent type='Potential' /> },
            { path: 'lead-detail/update/:id', element: <LeadDetailEditPotential /> },
            { path: 'create/:createId?', element: <CreatePotential /> },
            { path: 'update/:id', element: <UpdatePotential /> },
        ]
    },

    // Deal routes =====
    {
        path: '/deal',
        title: 'Deals',
        hasIndex: true,
        parentLayout: 'AppLayout',
        childRoutes: [
            { index: true, element: <Deals /> },
            { path: ':id/note', element: <Notes type='Deal' /> },
            { path: ':id/overview', element: <DealOverview /> },
            { path: ':id/task', element: <Tasks type='Deal' /> },
            { path: ':id/meeting', element: <Meetings type='Deal' /> },
            { path: ':id/call-information', element: <Calls type='Deal' /> },
            { path: ':id/lead-detail', element: <LeadDetailsComponent type='Deal' /> },
            { path: 'lead-detail/update/:id', element: <LeadDetailEditDeal /> },
            { path: 'create/:createId?', element: <CreateDeals /> },
            { path: 'update/:id', element: <UpdateDeals /> },
        ]
    },

    // Report routes =====
    {
        path: '/reports',
        title: 'Reports',
        hasIndex: true,
        parentLayout: 'AppLayout',
        childRoutes: [
            { index: true, element: <Report /> },
            { path: 'create', element: <CreateReports /> },
            { path: 'update/:id', element: <UpdateReports /> },
            { path: ':id/:module', element: <ListReports /> },
        ]
    },

    // Lost lead routes =====
    {
        path: '/lost-lead',
        title: 'Lost Leads',
        hasIndex: true,
        parentLayout: 'AppLayout',
        childRoutes: [
            { index: true, element: <LostLists /> },
            { path: ':id/note', element: <Notes type='LostLead' /> },
            { path: ':id/overview', element: <LostOverview /> },
            { path: ':id/task', element: <Tasks type='LostLead' /> },
            { path: ':id/meeting', element: <Meetings type='LostLead' /> },
            { path: ':id/call-information', element: <Calls type='LostLead' /> },
            { path: ':id/lead-detail', element: <LeadDetailsComponent type='lost_lead' /> },
        ]
    },

    // Contact list routes =====
    {
        title: 'Contact Lists',
        parentLayout: 'AppLayout',
        childRoutes: [
            { path: "/contact-lists", element: <ContactList /> }
        ]
    },

    // Third party routes =====
    {
        title: '',
        parentLayout: 'AppLayout',
        routeType: 'isPublicRoute',
        childRoutes: [
            { path: '/third-party', element: <ThirdParty /> }
        ]
    },

    // Search results routes =====
    {
        title: 'Search Results',
        parentLayout: 'AppLayout',
        childRoutes: [
            { path: '/global-search', element: <GlobalSearch /> }
        ]
    },

    // Personal Details routes =====
    {
        title: 'Personal',
        parentLayout: 'AppLayout',
        childRoutes: [
            { path: '/account-details/profile-details', element: <MyAccountDetails /> },
            { path: '/account-details/profile-details/edit', element: <EditDetails /> },
            { path: '/account/password', element: <Password /> },
            { path: '/account-details/your-company', element: <WhiteListDomin /> },
            { path: '/subscriptions/current-plan', element: <CurrentPlans /> },
            { path: '/account-details/update-currency', element: <Currency /> },
            { path: '/subscriptions/transaction-history', element: <TransactionHistorys /> },
            { path: '/subscriptions/payment-methods', element: <PaymentMethods /> },
            { path: '/account-details/company-addresses', element: <CompanyAddresses />}
        ]
    },

    // Roles and permissions routes =====
    {
        title: 'Company',
        parentLayout: 'AppLayout',
        childRoutes: [
            { path: '/roles-permissions/manage-users', element: <ManageUsers /> },
            { path: '/roles-permissions/manage-users/user-details/:id', element: <UserDetails /> },
            { path: '/roles-permissions/manage-users/user-details/update/:id', element: <UserOverviewChild /> },
            { path: '/roles-permissions/hierarchy', element: <ManageUsers /> },
            { path: '/roles-permissions/data-permission/:id', element: <DataPermissionPage /> },
        ]
    },

    // Campaign routes =====
    {
        path: '/campaign',
        title: 'Email Campaigns',
        hasIndex: true,
        parentLayout: 'AppLayout',
        childRoutes: [
            { index: true, element: <StartCampaign /> },
            { path: 'create', element: <CreateCampaign /> },
            { path: 'lists', element: <Campaigns /> },
            { path: 'templates', element: <Templates /> },
            { path: 'drafts', element: <Drafts /> },
            { path: 'archive', element: <Archive /> },
            { path: 'overview/:id', element: <CampaignOverview /> },
            { path: 'overview-list/:id', element: <Recipients /> },
        ]
    },

    // Campaign editor and preview related routes
    { path: '/campaign/preview', element: <Preview /> },
    { path: '/campaign/design/:label?', element: <EasyEditor /> },
    { path: '/campaign/design-2', element: <DesignTemplate /> },
    { path: '/campaign/edit-design/:id', element: <EasyEditor /> },


    // Master admin routes =====
    {
        title: 'Master Admin',
        parentLayout: 'AppMasterLayout',
        routeType: 'isMasterAdminRoute',
        childRoutes: [
            { path: '/master-admin/master-template', element: <MasterTemplate /> }
        ]
    },
    { path: '/master-admin/design/:label?', element: <EasyEditor />, routeType: 'isMasterAdminRoute' },
    { path: '/master-admin/preview', element: <Preview />, routeType: 'isMasterAdminRoute' },
    { path: '/master-admin/edit-design/:id', element: <EasyEditor />, routeType: 'isMasterAdminRoute' },

    //  Manage email preference route
    { path: '/email-preference/manage-preference/?', element: <ManagePreference />, routeType: 'isPublicRoute' },

    // Server status
    { path: '/status', element: <ServerStatus />, exact: true, routeType: 'isPublicRoute' },

    // Page not found
    { path: '/*', element: <PageNotFound />, exact: true, routeType: 'isPublicRoute' },
];