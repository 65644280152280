import { Box } from "@mui/material";
import OverviewChild from "./OverviewComponent";
import { lazy, Suspense } from "react";
import { lazyRetry,renderLazyLoader } from "../utils/chunkHandle";
const LeadTasks = lazy(() =>
  lazyRetry(() => import("../components/Leads/Tasks/Tasks"), "tasks")
);

const Tasks = ({ type }) => {
  return (
    <>
      <Box
        component="main"
        className="ma-mainTop-box ma-overview-main"
        sx={{ flexGrow: 1 }}
      >
          <OverviewChild type={type} />
          <Suspense fallback={renderLazyLoader()}>
          <LeadTasks type={type} />
        </Suspense>
      </Box>
    </>
  );
};

export default Tasks;
