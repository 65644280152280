import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Toaster } from "./pages/common/Toaster";
import { UNAUTHORISED_ERROR } from "./utils/constants";
import { logoutAndRedirectToLogin } from "./utils";

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const isAuthenticated = () => !!(localStorage.getItem("token"));

  useEffect(() => {
    if (!isAuthenticated() && !location.state?.action) {
      Toaster.TOAST(UNAUTHORISED_ERROR, "error");
      logoutAndRedirectToLogin();
    }
  }, []);

  return children;
};

export default PrivateRoute;
