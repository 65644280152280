import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import { Toaster } from '../common/Toaster';
import { restMethodError } from '../../constants/errorMessages';
import { ButtonLoader } from '../common/ButtonLoader';
import { NewsSubscriptionApi } from '../../apis/newsSubscriptionApi';
import { SubscriptionSchema } from '../common/ValidationSchema/NewsSubsSchema';
import { Formik, Form } from "formik";

export default function Subscribe() {

  const [initialData, setInitialData] = useState({
    email: "",
  });

  const handleSubscribe = (values, setSubmitting, resetForm) => {
    let data = {
      email: values.email
    }
    NewsSubscriptionApi.getSubscription(data)
      .then(response => {
        Toaster.TOAST(`${response.data.attributes.email} has been subscibed successfully.`, "success");
        setSubmitting(false);
        resetForm();
      }).catch(error => {
        Toaster.TOAST(restMethodError(error), "error");
        setSubmitting(false);
      })
  }

  return (
    <section className="ps-newslatter-sec">
      <div className="container">
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div
              className="ps-newslatter-box"
              data-aos="fade-right"
              data-aos-duration={300}
            >
              <h2>Subscribe to our <span>newsletter</span></h2>
              <p>
                Get the latest news, articles, and resources straight into your
                inbox weekly.
              </p>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <Formik
              initialValues={initialData}
              validationSchema={SubscriptionSchema}
              onSubmit={(values, { resetForm, setSubmitting }) => {
                setSubmitting(true);
                handleSubscribe(values, setSubmitting, resetForm);
              }
              }
            >
              {({ errors, touched, values, setFieldValue, isSubmitting }) => {
                return (
                  <Form
                    autoComplete='off'
                    action=""
                    className="ps-heading-newlatter"
                    data-aos="fade-left"
                    data-aos-duration={800}
                  >
                    <div className="ps-newlatter-form">
                      <div className="ps-filed-email">
                        <TextField
                          placeholder="Enter work email"
                          fullWidth
                          id="email"
                          name="email"
                          variant='outlined'
                          className="form-control"
                          value={values.email}
                          onChange={(event) => {
                            setFieldValue("email", event.target.value);
                          }}
                          error={touched.email && Boolean(errors.email)}
                        />
                      </div>
                      <div className="ps-login ps-explore-Pivott ps-newsLatter-btn">
                        <ButtonLoader
                          loading={isSubmitting}
                          classStyle={"ps-btn"}
                          style={{ textTransform: "none", boxShadow: "none" }}
                          btnType={"submit"}
                          title={"Subscribe"}
                        />
                      </div>
                    </div>
                    <p><span className="ps-error-msg" >{touched.email && errors.email}</span></p>
                    <p>
                      By filling this form you agree to our <Link to="/privacy-policy"> Privacy Policy</Link>
                    </p>
                  </Form>
                )
              }
              }
            </Formik>
          </div>
        </div>
      </div>
    </section>
  )
}
