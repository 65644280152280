import React, { useContext, useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './App.css'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { DataContext } from './context'
import '@fontsource/poppins'
import IdleTimeout from '../src/constants/IdleTimeout'
import { DeviceUUID } from 'device-uuid'

import { isOnValidDomain, logoutAndRedirectToLogin, needToSetLocalStorage, redirectToValidDomain, removeLocalStorageForUser, setLocalStorageData } from "./utils";
import { useCookies } from 'react-cookie'
import { USER_COOKIE } from './utils/constants'
import { routes } from './routes/Routes'
import { AppLayout, AppMasterLayout } from './Layout'
import PrivateRoute from './PrivateRoute'
import MasterPrivateRoute from './pages/MasterAdmin/Route/MasterPrivateRoute'

function App() {
  const { setDeviceAddress } = useContext(DataContext)
  const [cookies] = useCookies([]);

  const setInfoFromCookie = () => {
    const domain = cookies[USER_COOKIE]?.domain
    if (!isOnValidDomain(domain)) {
      return redirectToValidDomain(domain)
    }

    if (!cookies[USER_COOKIE]) {
      return removeLocalStorageForUser();
    }

    if (needToSetLocalStorage(cookies[USER_COOKIE])) {
      setLocalStorageData(cookies[USER_COOKIE])
    }
  }

  const DeviceAddress = () => {
    var uuid = new DeviceUUID().get()
    setDeviceAddress(uuid)
  }
  const logOut = () => {
    if (window.location.pathname === '/login') {
      return
    }
    logoutAndRedirectToLogin();
  };

  useEffect(() => {
    setInfoFromCookie();
    DeviceAddress()
    AOS.init()
    AOS.refresh()
  }, [])

  const renderLayout = (parentLayout, title = '', component) => {
    switch (parentLayout) {
      case 'AppLayout':
        return <AppLayout title={title} />
      case 'AppMasterLayout':
        return <AppMasterLayout title={title} />
      default:
        return component;
    }
  }

  const renderComponentBasedOnType = (routeType, component) => {
    switch (routeType) {
      case 'isPrivateRoute':
        return <PrivateRoute>{component}</PrivateRoute>
      case 'isMasterAdminRoute':
        return <MasterPrivateRoute>{component}</MasterPrivateRoute>
      case 'isPublicRoute':
      default:
        return component;
    }
  }

  const renderRoutes = () => {
    return routes.map(({ path, element = null, routeType = 'isPrivateRoute', exact = false, title = '', hasIndex = false, parentLayout = false, childRoutes = [] }) => (
      <Route
        {...((hasIndex || !parentLayout) && { path: path })}
        element={renderLayout(parentLayout, title, element)}
        {...(exact && { exact: true })}
      >
        {childRoutes && childRoutes.map((elem) => {
          const modifiedElem = { ...elem, element: renderComponentBasedOnType(routeType, elem.element) }
          return <Route {...modifiedElem} />
        })
        }
      </Route>
    ));
  };
  return (
    <>
      <IdleTimeout logOut={() => logOut()} />
      <div className='App'>
        <BrowserRouter>
          <Routes>
            {renderRoutes()}
          </Routes>
        </BrowserRouter>
      </div>
    </>
  )
}

export default App;
