import React, { useState } from 'react'
//mui
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
//mui icon
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useNavigate } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { ButtonLoader } from '../common/ButtonLoader'
import { Formik, Form } from 'formik'
import { Toaster } from '../common/Toaster'
import { AdminLoginSchema } from '../common/ValidationSchema/AdminLoginSchema'
import { restMethodError } from '../../constants/errorMessages'
import { MasterAdminApi } from '../../apis/MasterAdminApi'

export default function AdminLogin() {
  const [toggle_password, setToggle_password] = useState(false)
  const [initialData, setInitialData] = useState({
    email: '',
    password: ''
  })
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      document.getElementById('loginBtn').click()
    }
  }

  const togglePasswordHide = () => {
    setToggle_password(toggle_password => !toggle_password)
  }

  const handleLoginClick = values => {
    setLoading(true)
    let data = {
      email: values.email,
      password: values.password
    }

    if (values.email && values.password) {
      MasterAdminApi.create(data)
        .then(response => {
          setLoading(false)
          localStorage.setItem('master_token', response?.token)
          localStorage.setItem(
            "master_info",
            JSON.stringify(response?.data)
          );
          Toaster.TOAST(response?.message, "success");
          navigate('/master-admin/master-template')
        })
        .catch(error => {
          setLoading(false)
          Toaster.TOAST(restMethodError(error), 'error')
          console.log(error)
        })
    }
  }

  return (
    <Formik
      initialValues={initialData}
      onSubmit={values => handleLoginClick(values)}
      validationSchema={AdminLoginSchema}
    >
      {({ isValid, errors, touched, values, setFieldValue, isSubmitting }) => {
        return (
          <Form autoComplete='off'>
            <div className='ma-login-top'>
              <Grid
                container
                className='cardContainer'
                sx={{
                  height: '80vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Grid item xs={12} md={4} lg={4}>
                  <div className='ma-mainForm-contect'>
                    <div className='ma-mainScreen-form'>
                      <div className='ma-form-login FormInputs'>
                        <h3 data-testid='login' className='ma-form-heading'>
                          Master Admin Log in
                        </h3>
                        <div className='ma-main-login'>
                          <TextField
                            className='ma-Input-type placeholder_field'
                            data-testid='email-input'
                            fullWidth
                            id='email'
                            type='email'
                            placeholder='Enter email'
                            name='email'
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <MailOutlineIcon />
                                </InputAdornment>
                              )
                            }}
                            helperText={
                              <span className='ma-error'>
                                {touched.email && errors.email}
                              </span>
                            }
                            value={values.email}
                            onChange={event => {
                              setFieldValue('email', event.target.value)
                            }}
                            onKeyPressCapture={handleKeyPress}
                          />
                          <TextField
                            className='ma-Input-type placeholder_field'
                            data-testid='password-input'
                            fullWidth
                            name='password'
                            placeholder='Enter password'
                            type={toggle_password ? 'text' : 'password'}
                            id='password'
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <LockOutlinedIcon />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment
                                  position='end'
                                  sx={{ cursor: 'pointer' }}
                                >
                                  {' '}
                                  {toggle_password ? (
                                    <Visibility
                                      className='cursor_pointer'
                                      onClick={togglePasswordHide}
                                    />
                                  ) : (
                                    <VisibilityOff
                                      onClick={togglePasswordHide}
                                    />
                                  )}
                                </InputAdornment>
                              )
                            }}
                            helperText={
                              <span className='ma-error'>
                                {touched.password && errors.password}
                              </span>
                            }
                            value={values.password}
                            onChange={event => {
                              setFieldValue('password', event.target.value)
                            }}
                            onKeyPressCapture={handleKeyPress}
                          />
                          <div className='ma-login-btn'>
                            <ButtonLoader
                              loading={loading}
                              classStyle={'loginBtn'}
                              btnType={'submit'}
                              testid={'login-btn'}
                              title={'LOGIN'}
                              fullWidth={true}
                              id={'loginBtn'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
