import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Toaster } from "../../common/Toaster";
import { UNAUTHORISED_ERROR } from "../../../utils/constants";
import { logoutAndRedirectToMasterLogin } from "../../../utils";

const MasterPrivateRoute = ({ children }) => {
    const isAuthenticated = () => !!(localStorage.getItem("master_token"));

    let location = useLocation();
    
    useEffect(() => {
        if (!isAuthenticated() && !location.state?.action) {
            Toaster.TOAST(UNAUTHORISED_ERROR, "error");
            logoutAndRedirectToMasterLogin();
        }
    }, [])

    return children;
};

export default MasterPrivateRoute;
