import React, { Suspense, lazy } from "react";
import { lazyRetry } from "./utils/chunkHandle";
import LazyLoader from "./components/routesContainer";

const AppsBar = LazyLoader(
  lazy(() => lazyRetry(() => import('./components/AppsBar'), 'appsbar'))
)

const MasterNavBar = LazyLoader(
  lazy(() =>
      lazyRetry(() => import('./pages/MasterAdmin/Component/MasterNavBar'), 'masterNavbar')
  )
)

export const AppLayout = props => (
    <>
      <Suspense fallback={<AppsBar title={props?.title} />}>
        <AppsBar title={props?.title} />
      </Suspense>
    </>
  )
  
export const AppMasterLayout = props => (
    <>
      <Suspense fallback={<MasterNavBar title={props?.title}/>}>
        <MasterNavBar title={props?.title} />
      </Suspense>
    </>
  )