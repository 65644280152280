import { NEWS_SUBSCRIPTION } from "../constants/routes";
import { FetchApi } from "./fetchApi";

export const NewsSubscriptionApi = {
    getSubscription: (data) => {
        return FetchApi({
            path: NEWS_SUBSCRIPTION,
            type: "POST",
            data: JSON.stringify({data: data})
        })
    }
};
